<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="pmanualdetailca"
		:showActionDelete="false"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
	></detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import loading from '@/mixins/loading.mixin';
import { model, fields } from './catalog-section.form-data';
import apiCommon from '@/services/api/common.api';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'CatalogSection',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofthecata');
			const name = this.model.CsectionNameSk || '';
			const id = this.$t('id');
			const idNum = this.model.CsectionId;
			return `${title} ${name} ${id} ${idNum}`;
		},
	},

	methods: {
		async loadResources() {
			await this.loadObjectTypes();
		},

		async loadObjectTypes() {
			const values = await apiCommon
				.getStandardList({}, 'ObjectType')
				.then((res) => res.data)
				.then((res) => res.map((objType) => ({ value: objType.OtypeId, name: objType.OtypeNameSk })));
			// console.debug('loadObjectTypes: ', values);

			this.updateSchemaFields({ ObjectTypes: { values } });
		},
	},
};
</script>