var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          headerTitle: _vm.headerTitle,
          tooltipMsg: "pmanualdetailca",
          showActionDelete: false,
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }