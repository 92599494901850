import vueFormGenerator from 'vue-form-generator';

export const model = {
	CsectionId: 0,
	CsectionNameSk: null,
	CsectionUrlNameSk: null,
	CsectionNameCz: null,
	CsectionUrlNameCz: null,
	CsectionNamePl: null,
	CsectionUrlNamePl: null,
	CsectionNameDe: null,
	CsectionUrlNameDe: null,
	CsectionNameEn: null,
	CsectionUrlNameEn: null,
	CsectionNameHu: null,
	CsectionUrlNameHu: null,
	CsectionOrder: null,
	ObjectTypes: [],
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namesk2556',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNameSk',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnamesk',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namecz2558',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNameCz',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnamecz',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namepl2561',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNamePl',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnamepl',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'nameen2564',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNameEn',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnameen',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namede2562',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNameDe',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnamede',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'namehu2566',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'CsectionUrlNameHu',
				required: true,
				validator: vueFormGenerator.validators.required,
				i18n: {
					label: 'urlnamehu',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'CsectionOrder',
				styleClasses: 'quarter-width',
				i18n: {
					label: 'order2924',
				},
			},
			{
				type: 'switch',
				model: 'CsectionIsVisible',
				i18n: {
					label: 'displayedinsear3534',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'CsectionIsVisibleInMenu',
				i18n: {
					label: 'displayedinthem',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'checkboxes-group',
				model: 'ObjectTypes',
				values: [],
				i18n: {
					label: 'assignedobjectt',
				},
			},
		],
	},
];
